import React from "react";
import styles from "./About.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const About = () => {
  return (
    <div className={cx("aboutWrapper")}>
      <h3 className={cx("aboutTitle")}>About</h3>
      <div className={cx("aboutDesc")}>
        우리는 프로그래밍, 디자인, 생산성과 관련된 쓸모있는 콘텐츠를 제공합니다.
      </div>
    </div>
  );
};

export default About;
