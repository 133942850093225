import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import { Container, Row, Col } from "react-bootstrap";
import About from "../components/PageContact/About";

const Contact = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="연락하기" />
      <main role="main" style={{ marginTop: "56px" }}>
        <Container>
          <Row className="py-5">
            <Col lg={6} xl={5}>
              <About />
            </Col>
            <Col lg={6} xl={7}>
              <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
              <iframe
                title="airtable form"
                className="airtable-embed airtable-dynamic-height"
                src="https://airtable.com/embed/shrxO8BQh6pi4OloO?backgroundColor=blue"
                frameBorder="0"
                // onmousewheel=""
                width="100%"
                height="955"
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #ccc",
                }}
              ></iframe>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  );
};

export default Contact;
